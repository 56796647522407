import copy from "copy-to-clipboard";
import parse from "html-react-parser";
import { htmlToText } from "html-to-text";
import React, { useState } from "react";

import CopiedIcon from "../../assets/copied_icon.svg";
import CopyIcon from "../../assets/copy.svg";
import { StyledTooltip } from "../../styled/mui-styled";

import styles from "./styles.module.css";

interface Summary {
    text: string;
    keywords: string[];
}

interface QuestionCardProps {
    tag: number;
    question: string;
    overall_summary?: Summary;
    positive_summary?: Summary;
    negative_summary?: Summary;
    neutral_summary?: Summary;
    questionCardContentClassName?: string;
    children?: React.ReactNode;
    headerHeight?: string;
    contentHeight?: string;
    showCopyIcon?: boolean;
}

const SummaryRenderer: React.FC<{
    summary?: Summary;
    sentimentText?: string;
    sentimentColor?: string;
    isOverallSummary?: boolean;
    showCopyIcon?: boolean;
}> = ({ summary, sentimentText, sentimentColor, isOverallSummary, showCopyIcon = true }) => {
    const [copied, setCopied] = useState(false);

    const handleCopied = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };
    const handleCopyToClipboard = () => {
        if (summary) {
            const content = htmlToText(summary.text);
            copy(content);
            handleCopied();
        }
    };
    if (!summary) return null;
    return (
        <>
            {!isOverallSummary && (
                <div className={styles.sentimentContainer}>
                    <div className={styles.sentimentMarker} style={{ background: sentimentColor }}></div>
                    <div className={styles.sentimentText}>{sentimentText}</div>
                </div>
            )}
            {summary.keywords.length > 0 && (
                <div className={styles.keywordsContainer}>
                    <div className={styles.title}>Keywords:</div>
                    <span className={styles.keywords}>{summary.keywords.join(", ")}</span>
                </div>
            )}
            <div className={styles.summary}>
                {parse(summary.text)}
                {showCopyIcon && (
                    <div className={styles.image}>
                        <StyledTooltip title={copied ? "Copied!" : "Copy"}>
                            <img loading="lazy" src={copied ? CopiedIcon : CopyIcon} alt="copy" onClick={handleCopyToClipboard} />
                        </StyledTooltip>
                    </div>
                )}
            </div>
        </>
    );
};

const QuestionCard: React.FC<QuestionCardProps> = ({
    tag,
    question,
    overall_summary,
    positive_summary,
    negative_summary,
    neutral_summary,
    questionCardContentClassName = "",
    children,
    headerHeight = "87px",
    contentHeight = "80%",
    showCopyIcon = true,
}) => {
    const contentClassName = questionCardContentClassName || styles.questionCardContent;
    const hasPositive = !!positive_summary;
    const hasNegative = !!negative_summary;
    const hasNeutral = !!neutral_summary;

    return (
        <div className={styles.questionCard}>
            <div className={styles.questionCardHeader} style={{ height: headerHeight }}>
                <div className={styles.tag}>{tag}</div>
                <div className={styles.question}>{question}</div>
            </div>
            <div className={contentClassName} style={{ height: contentHeight }}>
                {overall_summary ? (
                    <SummaryRenderer summary={overall_summary} isOverallSummary showCopyIcon={showCopyIcon} />
                ) : (
                    <>
                        {hasPositive && (
                            <>
                                <SummaryRenderer summary={positive_summary} sentimentText="POSITIVE COMMENT" sentimentColor="#12A195" showCopyIcon={showCopyIcon} />
                                {(hasNegative || hasNeutral) && <hr className={styles.customHr} />}
                            </>
                        )}
                        {hasNegative && (
                            <>
                                <SummaryRenderer summary={negative_summary} sentimentText="NEGATIVE COMMENT" sentimentColor="#FF7A9F" showCopyIcon={showCopyIcon} />
                                {hasNeutral && <hr className={styles.customHr} />}
                            </>
                        )}
                        {hasNeutral && <SummaryRenderer summary={neutral_summary} sentimentText="NEUTRAL COMMENT" sentimentColor="#E6E6E6" showCopyIcon={showCopyIcon} />}
                    </>
                )}
                {children}
            </div>
        </div>
    );
};

export default QuestionCard;
