import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";

import React, { useState } from "react";

import { FORMAT_OPTIONS } from "../../constants/constants";
import { StyledRadioButton, RadioFormControlLabel } from "../../styled/mui-styled";

interface RowRadioButtonsGroupProps {
    onChange: (value: string) => void;
}

const RowRadioButtonsGroup: React.FC<RowRadioButtonsGroupProps> = ({ onChange }) => {
    const [selectedValue, setSelectedValue] = useState<string>("paragraph");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        onChange(newValue);
    };

    return (
        <FormControl>
            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={selectedValue} onChange={handleChange}>
                {FORMAT_OPTIONS.map(({ value, label }) => (
                    <RadioFormControlLabel key={value} value={value} control={<StyledRadioButton size="small" />} label={label} />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default RowRadioButtonsGroup;
