import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FilterState {
    selectedFilters: { [key: string]: string[] };
    detailedFilters: Record<string, { options: { column_value: string; display_name: string; is_applied: boolean | null }[]; display_name: string }>;
}

const initialState: FilterState = {
    selectedFilters: {},
    detailedFilters: {},
};

// Create the slice
const filterSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        setFilters: (
            state,
            action: PayloadAction<{
                selected: { [key: string]: string[] };
                details: Record<string, { options: { column_value: string; display_name: string; is_applied: boolean | null }[]; display_name: string }>;
            }>,
        ) => {
            state.selectedFilters = action.payload.selected;
            state.detailedFilters = action.payload.details;
        },
        resetSelectedFilters: (state) => {
            state.selectedFilters = {};
            state.detailedFilters = {};
        },
    },
});

// Export the actions
export const { setFilters, resetSelectedFilters } = filterSlice.actions;

// Export the reducer to add it to the store
export default filterSlice.reducer;
