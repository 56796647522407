import { Button, ButtonProps, Checkbox, Typography, FormControlLabel, TextField, DialogActions, Dialog, Radio, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

interface ResetButtonProps extends ButtonProps {
    editMode?: boolean;
}

export const FeedbackButton = styled(Button)`
    &.MuiButton-root {
        background-color: #386694;
        color: #fff;
        text-transform: inherit;
        border: 0px;
        border-radius: 4px 4px 0px 0px;
        height: 29px;
        width: 125px;
        padding: 6px 12px;
        font-size: 16px;
        font-family: McKinseySans-Regular;
        gap: 10px;
    }
`;
export const WordCloudButton = styled(Button)`
    &.MuiButton-root {
        background-color: #103559;
        color: #fff;
        text-transform: inherit;
        border: 0px;
        border-radius: 4px 4px 0px 0px;
        height: 29px;
        width: 125px;
        padding: 6px 12px;
        font-size: 16px;
        font-family: McKinseySans-Regular;
        gap: 10px;
    }
`;

export const CustomTypography = styled(Typography)`
    &.MuiTypography-root {
        font-size: 16px;
        font-family: MckinseySans-Regular;
        color: #333333;
        font-weight: 400;
    }
    &::after {
        content: "*";
        color: red;
    }
`;
export const StyledCheckbox = styled(Checkbox)`
    &.MuiCheckbox-root {
        padding: 10px;
    }
`;
export const StyledFormControlLabel = styled(FormControlLabel)`
    &.MuiFormControlLabel-root .MuiFormControlLabel-label {
        font-size: 16px;
        font-family: MckinseySans-Regular;
        color: #333333;
        font-weight: 400;
    }
`;
export const TextArea = styled(TextField)`
    &.MuiTextField-root {
        width: 380px;
    }
`;
export const DialogAction = styled(DialogActions)`
    &.MuiDialogActions-root {
        margin: 30px;
        display: flex;
        justify-content: center;
        gap: 20px;
    }
`;
export const CancelButton = styled(Button)`
    &.MuiButton-root {
        border: 1px solid rgba(5, 28, 44, 1);
        color: #051c2c;
        text-transform: math-auto;
        font-size: 16px;
        font-family: McKinseySans-Regular;
    }
`;
export const CustomDialog = styled(Dialog)(({ theme }) => ({
    ".MuiDialogTitle-root": {
        padding: "32px 32px 0px 32px",
        display: "flex",
        alignItems: "center",
        color: "#103559",
        fontSize: "32px",
        fontFamily: "MckinseySans-Regular",
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(4),
    },
}));
export const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#333333",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#333333",
    },
    width: "10%",
}));
export const StyledRadioButton = styled(Radio)`
    &.MuiRadio-root {
        color: #b3b3b3;
    }
    &.Mui-checked{
      color: #051C2C;
,
    },
`;
export const RadioFormControlLabel = styled(FormControlLabel)`
    &.MuiFormControlLabel-root .MuiFormControlLabel-label {
        font-size: 14px;
        font-family: MckinseySans-Regular;
        color: #333333;
        font-weight: 400;
    }
`;
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        width: "58.3rem",
    },
    "& .MuiDialogContent-root": {
        padding: "0px 60px",
    },
    ".MuiDialogTitle-root": {
        padding: theme.spacing(4),
        display: "flex",
        alignItems: "center",
        color: "#0679C3",
        fontSize: "28px",
        fontFamily: "MckinseySans-Regular",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(3),
    },
}));
export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#000000",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#000000",
    },
}));
export const ResetButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "editMode",
})<ResetButtonProps>(({ editMode }) => ({
    "&.MuiButton-root": {
        background: "#fff",
        border: "1px solid #051c2c",
        textTransform: "none",
        color: "#051c2c",
        boxShadow: "none",
        borderRadius: "4px",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
        textAlign: "center",
        fontFamily: "McKinseySans-Regular",
        height: "2rem",
        ...(editMode && {
            padding: 0,
            width: "6rem",
        }),
    },
}));
export const ApplyButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "editMode",
})<ResetButtonProps>(({ editMode }) => ({
    "&.MuiButton-root": {
        background: "#051c2c",
        border: "1px solid #051c2c",
        textTransform: "none",
        color: "#fff",
        boxShadow: "none",
        borderRadius: "4px",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
        textAlign: "center",
        fontFamily: "McKinseySans-Regular",
        height: "2rem",
        padding: "6px 16px",
        ...(editMode && {
            padding: 0,
            width: "10rem",
        }),
    },
}));

export const CustomListItem = styled(ListItem)`
    &.MuiListItem-root {
        padding: 0;
    },
`;
export const CustomCheckbox = styled(Checkbox)`
     &.MuiCheckbox-root {
        color: #B3B3B3;
        padding: 0;
        margin: 0 0.5rem;
        background: #fff;
        position: relative;
        left: -0.1vw;
        width: 18px;
        height: 18px;
    },
    &.Mui-checked {
        color: #051c2c;
        &:hover {
            color: #1b456e;
        },
    },
    &:hover {
        color: #1b456e;
    },
`;
export const CustomListItemIcon = styled(ListItemIcon)`
    &.MuiListItemIcon-root {
        min-width: 0;
    }
`;
export const CustomListItemText = styled(ListItemText)`
    &.MuiListItemText-root {
        margin-top: 0;
        margin-bottom: 0;
    }
    ,
    &.MuiListItemText-root .MuiTypography-root {
        color: #333333;
        font-size: 14px;
        line-height: 20px;
        font-family: MckinseySans-Regular;
    }
`;
export const CustomChildListItem = styled(ListItem)`
    &.MuiListItem-root {
        padding: 8px 0;
    },
`;
export const ViewAll = styled(Button)`
    &.MuiButton-root {
        text-transform: none;
        color: #2b5580;
        box-shadow: none;
        padding: 5px 25px;
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        text-align: center;
        font-family: McKinseySans-Medium;
    }
`;
export const PresetButton = styled(Button)`
    &.MuiButton-root {
        color: #2b5580;
        box-shadow: none;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        text-align: center;
        font-family: McKinseySans-Regular;
        padding: 0;
    }
`;
export const CustomFormControlLabel = styled(FormControlLabel)`
    &.MuiFormControlLabel-root .MuiFormControlLabel-label {
        color: #333333;
        font-size: 14px;
        line-height: 20px;
        font-family: MckinseySans-Regular;
    }
`;
export const CustomRadio = styled(Radio)`
    &.MuiRadio-root {
        margin-left: 0.5rem;
        color: #b3b3b3;
    }
    ,
    &.Mui-checked {
        color: #051c2c;
    }
`;
export const InfoButton = styled(Button)`
    &.MuiButton-root {
        color: #2b5580;
        box-shadow: none;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        text-align: center;
        font-family: McKinseySans-Regular;
        padding: 0;
    }
`;
export const DisabledButton = styled(Button)`
    &.MuiButton-root {
        background: #e6e6e6;
        border: none;
        text-transform: none;
        color: #b3b3b3;
        box-shadow: none;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        text-align: center;
        font-family: McKinseySans-Regular;
        height: 2rem;
    }
`;
export const DisabledSaveButton = styled(Button)`
    &.MuiButton-root {
        border: none;
        text-transform: none;
        color: #b3b3b3;
        box-shadow: none;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        text-align: center;
        font-family: McKinseySans-Regular;
        height: 1rem;
    }
`;
