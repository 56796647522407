/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ApiResponse<T> {
    data?: T;
    error?: Error;
    status?: number;
}

export class ApiWrapper {
    protected baseUrl: string;
    protected errorStatusCodes: number[] = [401, 403, 404, 409, 422, 500];
    private applicationToken: string | null = null;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    protected async fetchApplicationToken(): Promise<void> {
        const accessToken = window.sessionStorage.getItem("_mid-access-token");
        if (!accessToken) {
            throw new Error("Access token not found.");
        }

        try {
            const response = await fetch(`${this.baseUrl}/auth/access-token`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch application token.");
            }

            const data = await response.json();
            this.applicationToken = data.access_token;
        } catch (error) {
            console.error("Error fetching application token:", error);
            throw error;
        }
    }

    private async ensureApplicationToken(): Promise<void> {
        if (!this.applicationToken) {
            await this.fetchApplicationToken();
        }
    }

    private async fetchWithAuth<T>(endpoint: string, method: string, body?: any): Promise<ApiResponse<T>> {
        try {
            await this.ensureApplicationToken();

            const response = await fetch(`${this.baseUrl}${endpoint}`, {
                method,
                headers: {
                    Authorization: `Bearer ${this.applicationToken}`,
                    ...(body ? { "Content-Type": "application/json" } : {}),
                },
                redirect: "follow",
                body: body ? JSON.stringify(body) : undefined,
            });

            if (!response.ok) {
                if (this.errorStatusCodes.includes(response.status)) {
                    return { status: response.status };
                }
                const errorResponse = await response.json();
                const errorDetail = errorResponse?.detail || "Unknown error";
                throw new Error(errorDetail);
            }

            const data = await response.json();
            return { data, status: response.status };
        } catch (error) {
            return { error: error as Error };
        }
    }

    protected async get<T>(endpoint: string): Promise<ApiResponse<T>> {
        return this.fetchWithAuth<T>(endpoint, "GET");
    }

    protected async post<T>(endpoint: string, body: any): Promise<ApiResponse<T>> {
        return this.fetchWithAuth<T>(endpoint, "POST", body);
    }

    protected async patch<T>(endpoint: string, body: any): Promise<ApiResponse<T>> {
        return this.fetchWithAuth<T>(endpoint, "PATCH", body);
    }

    protected async delete<T>(endpoint: string): Promise<ApiResponse<T>> {
        return this.fetchWithAuth<T>(endpoint, "DELETE");
    }

    protected async put<T>(endpoint: string, body: any): Promise<ApiResponse<T>> {
        return this.fetchWithAuth<T>(endpoint, "PUT", body);
    }
}
