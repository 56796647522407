import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, RadioGroup, List, IconButton } from "@mui/material";

import React, { useState } from "react";

import { useAppSelector } from "../../store/hooks";

import { CustomListItem, CustomFormControlLabel, CustomRadio } from "../../styled/mui-styled";

import styles from "./styles.module.css";

type Preset = {
    id: string;
    name: string;
};

interface PresetFilterProps {
    presets: Preset[];
    onPresetSelect: (presetId: string) => void; // Prop for radio selection
    onIconClick: (presetId: string, presetName: string) => void; // Separate prop for icon click
}

const PresetFilter: React.FC<PresetFilterProps> = ({ presets, onPresetSelect, onIconClick }) => {
    const [selectedValue, setSelectedValue] = useState<string>("");
    const selectedPresetId = useAppSelector((state) => state.preset.selectedPresetId);

    // Handle radio button change (selecting a preset)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const presetId = event.target.value;
        const selectedPreset = presets.find((p) => p.id === presetId);
        setSelectedValue(presetId);

        // Pass the selected preset's id and name to the parent component
        if (selectedPreset) {
            onPresetSelect(selectedPreset.id);
        }
    };

    return (
        <Box>
            <RadioGroup value={selectedValue || selectedPresetId} onChange={handleChange}>
                <List>
                    {presets.map((preset) => (
                        <React.Fragment key={preset.id}>
                            <CustomListItem
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="details"
                                        onClick={() => onIconClick(preset.id, preset.name)} // Icon button click handler
                                    >
                                        <KeyboardArrowRightIcon fontSize="small" />
                                    </IconButton>
                                }
                            >
                                <CustomFormControlLabel
                                    value={preset.id}
                                    control={<CustomRadio size="small" />}
                                    label={preset.name} // Use preset name for the label
                                />
                            </CustomListItem>
                            <hr className={styles.separator} />
                        </React.Fragment>
                    ))}
                </List>
            </RadioGroup>
        </Box>
    );
};

export default PresetFilter;
