import c from "classnames";
import React from "react";

import { NavLink, useLocation } from "react-router-dom";

import AppIcon from "../../assets/app_logo.svg";

import config from "../../config/config";

import { useAppSelector } from "../../store/hooks";

import styles from "./styles.module.css";

const Header = () => {
    const selectedSurveyId = useAppSelector((state) => state.surveys.selectedSurveyId);

    const location = useLocation();

    const isMySurveysActive = () => {
        return location.pathname.startsWith(`${config.app.REACT_APP_NAVIGATE_PATH}/surveys/${selectedSurveyId}`);
    };

    return (
        <div className={styles.appbar}>
            <img src={AppIcon} alt="logo" />
            <div className={styles.nav}>
                <NavLink to={config.app.REACT_APP_NAVIGATE_PATH} className={({ isActive }) => c(styles.headerLink, { [styles.activeHeaderLink]: isActive })} end>
                    <span className={styles.navLink}>Home</span>
                </NavLink>
                {selectedSurveyId ? (
                    <NavLink
                        to={`${config.app.REACT_APP_NAVIGATE_PATH}/surveys/${selectedSurveyId}/*`}
                        className={({ isActive }) => c(styles.headerLink, { [styles.activeHeaderLink]: isMySurveysActive() || isActive })}
                    >
                        <span className={styles.navLink}>My Surveys</span>
                    </NavLink>
                ) : (
                    <span className={styles.disabledLink}>
                        <span className={styles.navLink}>My Surveys</span>
                    </span>
                )}
            </div>
        </div>
    );
};
export default Header;
