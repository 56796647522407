import React from "react";

import Error from "../../assets/error.svg";

import styles from "./styles.module.css";

interface MessageCardProps {
    title: string;
    subtitle: string;
    text: string;
}

const MessageCard: React.FC<MessageCardProps> = ({ title, subtitle, text }) => {
    return (
        <div className={styles.message}>
            <img src={Error} alt="error" />
            <div className={styles.title}>{title}</div>
            <div className={styles.subtitle}>{subtitle}</div>
            <div className={styles.subtitle}>{text}</div>
        </div>
    );
};
export default MessageCard;
