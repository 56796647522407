import React from "react";

import RightArrow from "../../assets/arrow-right.svg";
import SurveyId from "../../assets/survey-id.svg";
import SurveyName from "../../assets/survey-name.svg";

import styles from "./styles.module.css";

interface CardProps {
    clientName: string;
    confirmitPID: string;
    surveyId: string;
    surveyName: string;
    onViewClick: (surveyId: string) => void;
}

const Card: React.FC<CardProps> = ({ clientName, surveyId, surveyName, onViewClick, confirmitPID }) => {
    return (
        <div className={styles.card}>
            <div className={styles.cardHeader}>{clientName}</div>
            <div className={styles.cardContent}>
                <div className={styles.cardDetails}>
                    <div className={styles.row1}>
                        <div className={styles.innerContainer}>
                            <img src={SurveyId} alt="" />
                            <span className={styles.textStyle}>Survey ID</span>
                        </div>
                        <div className={styles.text1}>{confirmitPID}</div>
                    </div>
                    <div className={styles.row2}>
                        <div className={styles.innerContainer}>
                            <img src={SurveyName} alt="" />
                            <span className={styles.textStyle}>Survey Name</span>
                        </div>
                        <div className={styles.text2}>{surveyName}</div>
                    </div>
                </div>
                <hr className={styles.separator} />
                <div className={styles.cardFooter}>
                    <span onClick={() => onViewClick(surveyId)} style={{ cursor: "pointer" }}>
                        VIEW
                    </span>
                    <span>
                        <img src={RightArrow} alt="arrow" />
                    </span>
                </div>
            </div>
        </div>
    );
};
export default Card;
