import React from "react";

import styles from "./styles.module.css";

interface LinearProgressProps {
    sentimentBreakdown: {
        negative: number;
        neutral: number;
        positive: number;
    };
    height?: number;
}

const sentimentColors: Record<keyof LinearProgressProps["sentimentBreakdown"], string> = {
    negative: "#FF7A9F",
    neutral: "#E6E6E6",
    positive: "#12A195",
};

const LinearProgress: React.FC<LinearProgressProps> = ({ sentimentBreakdown, height = 20 }) => {
    const sentimentOrder: (keyof typeof sentimentBreakdown)[] = ["negative", "neutral", "positive"];

    const filteredSentiments = sentimentOrder.filter((sentiment) => sentimentBreakdown[sentiment] > 0);

    return (
        <div className={styles.container} style={{ height }}>
            {filteredSentiments.map((sentiment, index) => (
                <span
                    key={sentiment}
                    style={{
                        width: `${sentimentBreakdown[sentiment]}%`,
                        background: sentimentColors[sentiment],
                        borderRadius: filteredSentiments.length === 1 ? "4px" : index === 0 ? "4px 0 0 4px" : index === filteredSentiments.length - 1 ? "0 4px 4px 0" : "0",
                    }}
                >
                    {sentimentBreakdown[sentiment]}
                </span>
            ))}
        </div>
    );
};

export default LinearProgress;
