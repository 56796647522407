import React from "react";

import styles from "./styles.module.css";

interface NumericalBadgeProps {
    numerator: number;
    denominator: number;
}

const NumericalBadge: React.FC<NumericalBadgeProps> = ({ numerator, denominator }) => {
    return <div className={styles.numericalBadge}>{`${numerator}/${denominator}`}</div>;
};

export default NumericalBadge;
