import { createSlice } from "@reduxjs/toolkit";

interface SnackbarState {
    open: boolean;
}

const initialState: SnackbarState = {
    open: false,
};

const snackbarSlice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        showSnackbar: (state) => {
            state.open = true;
        },
        hideSnackbar: (state) => {
            state.open = false;
        },
    },
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
