import React from "react";

import Select, { SelectProps } from "react-dropdown-select";

import styles from "./styles.module.css";

interface SelectDropdownProps<T extends string | object> extends Omit<SelectProps<T>, "options" | "labelField" | "valueField" | "onChange"> {
    options: T[];
    labelField: Extract<keyof T, string>;
    valueField: Extract<keyof T, string>;
    placeholder?: string;
    onChange: (values: T[]) => void;
    width?: string;
}

const SelectDropdown = <T extends string | object>({
    options,
    labelField,
    valueField,
    placeholder = "Select an option",
    onChange,
    multi = false,
    dropdownGap = 5,
    width = "100%",
    ...rest
}: SelectDropdownProps<T>) => {
    return (
        <div className={styles.customSelect} style={{ width }}>
            <Select options={options} labelField={labelField} valueField={valueField} placeholder={placeholder} onChange={onChange} multi={multi} dropdownGap={dropdownGap} {...rest} />
        </div>
    );
};

export default SelectDropdown;
