import React from "react";

import styles from "./styles.module.css";

interface SentimentProps {
    dotColor: string;
    sentimentText: string;
    number: number;
    showDot?: boolean;
}

const Sentiment: React.FC<SentimentProps> = ({ dotColor, sentimentText, number, showDot = true }) => {
    return (
        <>
            <div className={styles.gridElement}>
                {showDot && <div className={styles.dot} style={{ background: dotColor }}></div>}
                <span className={styles.sentimentText}>{sentimentText}</span>
                <span className={styles.commentText}>Comments</span>
            </div>
            <div className={styles.number}>{number}</div>
        </>
    );
};

export default Sentiment;
