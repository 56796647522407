/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Mid } from "@mid/sdk";
import { ERROR_MSG } from "@mid/sdk";
import { useEffect, useState, useCallback, createContext } from "react";
import { useNavigate } from "react-router-dom";

import AuthLoader from "../loader/auth-loader";

interface McKIdProps {
    mid: Mid;
    children?: React.ReactNode;
    mockedIsAuthed?: any;
    mockLoadFlag?: any;
}

export const AuthContext = createContext({
    isAuthed: null,
    isLoading: null,
});

export const MckId: React.FC<McKIdProps> = ({ mid, children, mockedIsAuthed, mockLoadFlag }: McKIdProps) => {
    const navigate = useNavigate();
    const [isAuthed, setIsAuthed] = useState(mockedIsAuthed || false);
    const [isLoading, setIsLoading] = useState(mockLoadFlag || true);
    const errorMsg = mid.core.state.get(ERROR_MSG);

    const checkIsAuth = useCallback(() => {
        mid.isAuthed().then((isAuth) => {
            if (isAuth) {
                setIsAuthed(true);
                setIsLoading(false);
            }
        });
    }, []);

    const login = useCallback(() => {
        mid.login().finally(() => {
            checkIsAuth();
        });
    }, [checkIsAuth]);

    useEffect(() => {
        login();
    }, [login]);

    const redirectToError = () => {
        navigate("/unauthorized");
        return null;
    };

    const content = isLoading ? <AuthLoader isLoading={isLoading} errorMsg={errorMsg} size={50} /> : isAuthed ? <>{children}</> : redirectToError();

    return (
        <AuthContext.Provider
            value={{
                isAuthed,
                isLoading,
            }}
        >
            {content}
        </AuthContext.Provider>
    );
};

export default MckId;
