import React from "react";

import GaugeChart from "react-gauge-chart";

import { convertToPercent } from "../../utils/utility-function";

interface GaugeProps {
    percent: number;
}

const Gauge: React.FC<GaugeProps> = ({ percent }) => {
    return (
        <div style={{ width: "300px", height: "200px", marginTop: "30px" }}>
            <GaugeChart
                id="gauge-chart"
                nrOfLevels={6}
                arcPadding={0.1}
                arcsLength={[0.04, 0.04, 0.04, 0.05, 0.06, 0.07]}
                arcWidth={0.3}
                cornerRadius={3}
                colors={["#FF7A9F", "#FF7A9F", "#FF7A9F", "#12A195", "#12A195", "#12A195"]}
                percent={convertToPercent(percent)}
                hideText={true}
                needleColor="#4D4D4D"
            />
        </div>
    );
};
export default Gauge;
