import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import config from "../../config/config";

const LoginCallback = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(config.app.REACT_APP_NAVIGATE_PATH);
    });
    return <></>;
};
export default LoginCallback;
