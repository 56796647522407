import React from "react";

import styles from "./styles.module.css";

const SummaryLoader = () => {
    return (
        <div className={styles.filterLoader}>
            <div className={styles.summaryLoaderWrapper}>
                <div className={styles.loaderAnimation}></div>
                <div className={styles.loaderAnimation}></div>
                <div className={styles.loaderAnimation}></div>
                <div className={styles.loaderAnimation}></div>
                <div className={styles.loaderAnimation}></div>
            </div>
            <div style={{ marginTop: "4rem" }}>
                <h2 className={styles.text}>Generating your customised summary...</h2>
                <p className={styles.subText}>This may take a minute.</p>
                <p className={styles.subText}>Feel free to explore other sections in the meantime.</p>
            </div>
        </div>
    );
};
export default SummaryLoader;
