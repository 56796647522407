import React from "react";

import CheckMark from "../../assets/checkmark.svg";

import styles from "./styles.module.css";

interface PersistentTagsProps {
    tags: string[];
    activeTags: string[];
    onTagClick: (tag: string) => void;
}

const PersistentTags: React.FC<PersistentTagsProps> = ({ tags, activeTags, onTagClick }) => {
    return (
        <>
            {tags.map((tag, index) => (
                <div key={index} className={`${styles.tagContainer} ${activeTags.includes(tag) ? styles.active : styles.inactive}`} onClick={() => onTagClick(tag)}>
                    {activeTags.includes(tag) && (
                        <span className={styles.checkmark}>
                            <img src={CheckMark} alt="" />
                        </span>
                    )}
                    {tag}
                </div>
            ))}
        </>
    );
};

export default PersistentTags;
