import { WORDCLOUD_PLACEHOLDER } from "../constants/constants";
import { UserPresetFilters } from "../services/api-handlers";

export const mapSliderValueToLength = (value: number): string => {
    switch (value) {
        case 0:
            return "short";
        case 100:
            return "long";
        default:
            return "Unknown";
    }
};
export const mapSummaryTypeToSentiment = (value: string): string => {
    switch (value) {
        case "positive":
            return "positive_summary";
        case "negative":
            return "negative_summary";
        case "neutral":
            return "neutral_summary";
        default:
            return "Unknown";
    }
};

export const convertToPercent = (value: number, maxValue = 100) => {
    if (value > maxValue) value = maxValue;
    if (value < 0) value = 0;
    return value / maxValue;
};
export const getLastPathSegment = (pathname: string) => {
    const pathSegments = pathname.split("/");
    return pathSegments[pathSegments.length - 1] || "";
};
export const getLabelByPathSegment = (lastSegment: string): string => {
    switch (lastSegment) {
        case "workplace-themes":
            return WORDCLOUD_PLACEHOLDER.theme;
        case "text-summarisation":
            return WORDCLOUD_PLACEHOLDER.textSummarisation;
        default:
            return "Select Question";
    }
};
export const formatNumber = (num: number): string => {
    if (num >= 1e9) {
        return (num / 1e9).toFixed(1) + "B"; // Billions
    } else if (num >= 1e6) {
        return (num / 1e6).toFixed(1) + "M"; // Millions
    } else if (num >= 1e3) {
        return (num / 1e3).toFixed(1) + "K"; // Thousands
    }
    return num.toString(); // Less than 1000
};
export const getFilterById = (id: string, filtersArray: UserPresetFilters | null) => {
    if (filtersArray) {
        return filtersArray.find((filter) => filter.id === id);
    }
    return undefined;
};
