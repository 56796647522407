import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";

import LoginCallback from "./components/auth/login-redirect";
import MckId from "./components/auth/mck-id";
import Header from "./components/header/header";
import Home from "./components/home/home";
import Survey from "./components/surveys/survey";
import config from "./config/config";
import { APPLICATION_TOKEN_ERROR } from "./constants/constants";
import { extendedApiWrapper } from "./services/api-handlers";
import { buildMid } from "./utils/mck-id";

function App() {
    useEffect(() => {
        const fetchToken = async () => {
            try {
                await extendedApiWrapper.initialize();
            } catch (error) {
                console.error(APPLICATION_TOKEN_ERROR, error);
            }
        };

        fetchToken();
    }, []);
    return (
        <MckId mid={buildMid()}>
            <div className="App">
                <Header />
                <Routes>
                    <Route path={`${config.app.REACT_APP_NAVIGATE_PATH}/login/callback`} element={<LoginCallback />} />
                    <Route path={config.app.REACT_APP_NAVIGATE_PATH} element={<Home />} />
                    <Route path={`${config.app.REACT_APP_NAVIGATE_PATH}/surveys/:surveyId/*`} element={<Survey />} />
                    <Route path="*" element={<Navigate to={config.app.REACT_APP_NAVIGATE_PATH} replace />} />
                </Routes>
            </div>
        </MckId>
    );
}

export default App;
