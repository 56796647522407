/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { extendedApiWrapper, SurveyDetailsResponse } from "../services/api-handlers";

// Define the initial state for the slice
interface SurveyDetailsState {
    loading: boolean;
    error: string | null;
    data: SurveyDetailsResponse | null;
}

const initialState: SurveyDetailsState = {
    loading: false,
    error: null,
    data: null,
};

// Define the async thunk for fetching survey details
export const fetchSurveyDetails = createAsyncThunk("surveyDetails/fetchSurveyDetails", async (surveyId: string, { rejectWithValue }) => {
    try {
        const response = await extendedApiWrapper.getSurveyDetails(surveyId);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

// Create the slice
const surveyDetailsSlice = createSlice({
    name: "surveyDetails",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSurveyDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSurveyDetails.fulfilled, (state, action: PayloadAction<SurveyDetailsResponse | undefined>) => {
                state.loading = false;
                if (action.payload) {
                    state.data = action.payload;
                } else {
                    state.error = "Failed to fetch survey details";
                }
            })
            .addCase(fetchSurveyDetails.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload || "An error occurred";
            });
    },
});

export const surveyDetailsReducer = surveyDetailsSlice.actions;
export default surveyDetailsSlice.reducer;
