import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

import DisabledRightArrow from "../../assets/disabled-right.svg";
import EnabledLeftArrow from "../../assets/enabled-left.svg";
import DisabledLeftArrow from "../../assets/left-arrow.svg";
import EnabledRightArrow from "../../assets/right-arrow.svg";
import { NO_WORDCLOUD_DATA } from "../../constants/constants";
import { WordCount, extendedApiWrapper, QuestionListResponse } from "../../services/api-handlers";
import { useAppSelector } from "../../store/hooks";
import QuestionCard from "../text-summarisation/question-card";
import WordCloudChart from "../word-cloud/highcharts-word-cloud";

import styles from "./styles.module.css";

export interface WordCloudItem {
    name: string;
    weight: number;
}

const WordCloud = () => {
    const [questions, setQuestions] = useState<QuestionListResponse[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [wordCloudData, setWordCloudData] = useState<WordCloudItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const selectedSurveyId = useAppSelector((state) => state.surveys.selectedSurveyId);
    const filters = useAppSelector((state) => state.selectedFilters.selectedFilters);

    useEffect(() => {
        const fetchQuestions = async () => {
            if (selectedSurveyId) {
                try {
                    const response = await extendedApiWrapper.getQuestionsList(selectedSurveyId);
                    if (response.data) {
                        const questionList = response.data.slice(0, 3);
                        setQuestions(questionList);
                    } else {
                        setError("Failed to fetch questions");
                    }
                } catch (err) {
                    setError("An error occurred while fetching questions");
                }
            }
        };

        fetchQuestions();
    }, [selectedSurveyId]);

    const fetchWordCloudData = async (appliedFilters: Record<string, string[]> | null = null) => {
        if (selectedSurveyId && questions.length > 0) {
            setLoading(true);
            try {
                const requestBody = {
                    limit: 50,
                    filter_by: `question_id:${questions[currentQuestionIndex].id}`,
                    filters: appliedFilters ?? filters,
                };
                const response = await extendedApiWrapper.getWordCloud(selectedSurveyId, requestBody);
                if (response.data) {
                    const mappedData = response.data.map((item: WordCount) => ({
                        name: item.word,
                        weight: item.count,
                    }));
                    setWordCloudData(mappedData);
                } else {
                    setError("Failed to fetch word cloud data");
                }
            } catch (err) {
                setError("An error occurred while fetching word cloud data");
            } finally {
                setLoading(false);
            }
        }
    };
    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            fetchWordCloudData(filters);
        } else {
            fetchWordCloudData();
        }
    }, [questions, currentQuestionIndex, selectedSurveyId, filters]);

    const handleNext = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    if (loading) {
        return (
            <div className={styles.loader}>
                <CircularProgress size={50} />
            </div>
        );
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (wordCloudData.length === 0) {
        return <p>{NO_WORDCLOUD_DATA}</p>;
    }

    return (
        <div>
            <QuestionCard tag={currentQuestionIndex + 1} question={questions[currentQuestionIndex].name}>
                <div>
                    <WordCloudChart data={wordCloudData} wordSpaces={4} />
                </div>
                <div className={styles.paginationCol}>
                    <div className={styles.left} onClick={currentQuestionIndex > 0 ? handlePrevious : undefined}>
                        <img src={currentQuestionIndex > 0 ? EnabledLeftArrow : DisabledLeftArrow} alt="Previous" />
                    </div>
                    <div className={styles.right} onClick={currentQuestionIndex < questions.length - 1 ? handleNext : undefined}>
                        <img src={currentQuestionIndex < questions.length - 1 ? EnabledRightArrow : DisabledRightArrow} alt="Next" />
                    </div>
                </div>
            </QuestionCard>
        </div>
    );
};

export default WordCloud;
