import { Mid } from "@mid/sdk";

import config from "../config/config";

let mid: Mid;
export function buildMid(): Mid {
    if (typeof window !== "undefined") {
        mid = new Mid({
            loginInfo: {
                appId: `${config.oidc.REACT_APP_OIDC_CLIENT_ID}`,
                authDriver: "mid",
                flow: "auth_code",
                oidcConfigUrl: `${config.oidc.REACT_APP_OIDC_CONFIG_URL}`,
                fm: `${config.oidc.REACT_APP_OIDC_FM}`,
            },
            logoutRedirectUrl: `${config.oidc.REACT_APP_REDIRECT_BASE_URL}`,
            redirectUrl: `${config.oidc.REACT_APP_REDIRECT_BASE_URL}/login/callback`,
            landingFn: async () => {
                window.location.hash = "";
            },
        });
    }
    return mid;
}
