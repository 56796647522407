/* eslint-disable @typescript-eslint/no-explicit-any */
import Highcharts from "highcharts";
import HighchartsWordcloud from "highcharts/modules/wordcloud";
import HighchartsReact from "highcharts-react-official";

import React from "react";

HighchartsWordcloud(Highcharts);

interface WordCloudChartProps {
    data: { name: string; weight: number }[];
    wordSpaces?: number;
}

const WordCloudChart: React.FC<WordCloudChartProps> = ({ data, wordSpaces = 0 }) => {
    const options: Highcharts.Options = {
        chart: {
            events: {
                load: function () {
                    const allSeries = this.series;
                    const noBreakSpace = String.fromCharCode(0xa0);
                    const spacing = noBreakSpace.repeat(wordSpaces); // Capture wordSpaces here

                    allSeries.forEach((series) => {
                        if (series.type !== "wordcloud") return;

                        // Cast series options to SeriesWordcloudOptions to access `data` safely
                        const wordcloudSeries = series.options as Highcharts.SeriesWordcloudOptions;
                        if (wordcloudSeries.data) {
                            wordcloudSeries.data = wordcloudSeries.data.map((point) => {
                                if (typeof point === "object" && "name" in point) {
                                    // Modify point only if it has a `name` property
                                    return {
                                        ...point,
                                        name: `${spacing}${point.name}${spacing}`,
                                    };
                                }
                                return point; // If it’s not an object with a `name`, return as-is
                            });
                        }
                        series.setData(wordcloudSeries.data as any); // Cast to any to avoid type conflict in setData
                    });
                },
            },
        },
        series: [
            {
                type: "wordcloud",
                data: data,
                states: {
                    hover: {
                        color: "#0679C3",
                    },
                },
            },
        ],
        credits: {
            enabled: false,
        },
        title: {
            text: "",
        },
        tooltip: {
            useHTML: true,
            shape: "rect",
            distance: 10,
            backgroundColor: "#000000",
            style: {
                color: "#ffffff",
                fontSize: "10px",
            },
            formatter: function () {
                const point = this.point as any;
                return `<b>This word appeared in <b>${point.weight}</b> responses`;
            },
            shadow: false,
        },
        plotOptions: {
            wordcloud: {
                colors: ["grey"],
                minFontSize: 16,
                maxFontSize: 50,
                rotation: {
                    from: 0,
                    to: 0,
                    orientations: 2,
                },
                placementStrategy: "center",
                animation: {
                    duration: 3000,
                },
            },
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default WordCloudChart;
