import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, FormControl, FormGroup, Grid, IconButton, Snackbar } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";

import PenIcon from "../../assets/blue_pen.svg";
import { FEEDBACK_SUBJECTS, FEEDBACK_DESCRIPTION_ERROR, FEEDBACK_SUBJECT_ERROR } from "../../constants/constants";
import { extendedApiWrapper } from "../../services/api-handlers";
import { CustomTypography, StyledCheckbox, StyledFormControlLabel, TextArea, DialogAction, CancelButton, BootstrapDialog } from "../../styled/mui-styled";
import { Uploader } from "../image-uploader/image-uploader";

import styles from "./styles.module.css";

interface DialogProps {
    open: boolean;
    handleClose: () => void;
}

const Feedback: React.FC<DialogProps> = ({ open, handleClose }) => {
    const [feedbackSubject, setFeedbackSubject] = useState<string[]>([]);
    const [description, setDescription] = useState<string>("");
    const [image, setImage] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState<{ subject?: string; description?: string; image?: string }>({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const handleCheckboxChange = (subject: string) => {
        setFeedbackSubject((prev) => (prev.includes(subject) ? prev.filter((item) => item !== subject) : [...prev, subject]));
        if (feedbackSubject.length > 0) {
            setErrors((prevErrors) => ({ ...prevErrors, subject: undefined }));
        }
    };

    const handleImageUpload = (base64Image: string) => {
        setImage(base64Image);
        setErrors((prevErrors) => ({ ...prevErrors, image: undefined }));
    };

    const validateFields = () => {
        const newErrors: { subject?: string; description?: string; image?: string } = {};
        if (feedbackSubject.length === 0) newErrors.subject = FEEDBACK_SUBJECT_ERROR;
        if (!description) newErrors.description = FEEDBACK_DESCRIPTION_ERROR;
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateFields()) return;

        setIsSubmitting(true);

        const requestBody = {
            feedback_subject: feedbackSubject,
            description,
            image,
        };

        try {
            const response = await extendedApiWrapper.sendFeedback(requestBody);
            if (response.data?.success) {
                setSnackbarMessage("Feedback submitted successfully!");
                setSnackbarOpen(true);
                setFeedbackSubject([]);
                setDescription("");
                setImage(null);
                handleClose();
            } else {
                setSnackbarMessage("Failed to submit feedback. Please try again.");
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error("Error submitting feedback", error);
            setSnackbarMessage("An error occurred. Please try again later.");
            setSnackbarOpen(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <React.Fragment>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="xl">
                <DialogTitle id="customized-dialog-title">
                    <span style={{ marginRight: "10px", display: "flex" }}>
                        <img src={PenIcon} alt="" width={22} height={22} />
                    </span>
                    <span>Feedback Form</span>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <FormGroup>
                        <FormControl required error={!!errors.subject}>
                            <CustomTypography>Feedback subject</CustomTypography>
                            <Grid container spacing={2}>
                                {Array.isArray(FEEDBACK_SUBJECTS) &&
                                    FEEDBACK_SUBJECTS.map((subject) => (
                                        <Grid item key={subject}>
                                            <StyledFormControlLabel
                                                control={<StyledCheckbox checked={feedbackSubject.includes(subject)} onChange={() => handleCheckboxChange(subject)} />}
                                                label={subject}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                            {errors.subject && <Box className={styles.errorText}>{errors.subject}</Box>}
                        </FormControl>
                    </FormGroup>
                    <Box className={styles.infoText}>Select a feedback category</Box>
                    <Box className={styles.container}>
                        <Box className={styles.comment}>
                            <Box className={styles.feedback}>Feedback</Box>
                            <Box>
                                <TextArea
                                    multiline
                                    minRows={6}
                                    maxRows={8}
                                    variant="outlined"
                                    name="description"
                                    placeholder="Enter your feedback"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                    error={!!errors.description}
                                />
                                {errors.description && <Box className={styles.errorText}>{errors.description}</Box>}
                            </Box>
                        </Box>
                        <Box className={styles.imageContainer}>
                            <Box sx={{ marginBottom: "10px" }}>Add image</Box>
                            <Box className={styles.uploaderContainer}>
                                <Uploader onImageUpload={handleImageUpload} />
                                {errors.image && <Box className={styles.errorText}>{errors.image}</Box>}
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogAction>
                    <CancelButton onClick={handleClose} variant="outlined">
                        Cancel
                    </CancelButton>
                    <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} sx={{ textTransform: "math-auto" }}>
                        {isSubmitting ? "Sending..." : "Send Feedback"}
                    </Button>
                </DialogAction>
            </BootstrapDialog>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message={snackbarMessage} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} />
        </React.Fragment>
    );
};

export default Feedback;
