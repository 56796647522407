interface IENV {
    oidc: {
        REACT_APP_OIDC_CLIENT_ID: string;
        REACT_APP_REDIRECT_BASE_URL: string;
        REACT_APP_OIDC_CONFIG_URL: string;
        REACT_APP_OIDC_FM: string;
    };
    app: {
        REACT_APP_BASE_URL: string;
        REACT_APP_NAVIGATE_PATH: string;
    };
}
const local = {
    oidc: {
        REACT_APP_OIDC_CLIENT_ID: "f7f81a44-ebb7-4119-b491-ebdfdb849b1f",
        REACT_APP_REDIRECT_BASE_URL: "http://localhost:3000",
        REACT_APP_OIDC_CONFIG_URL: "https://auth.int.mckinsey.id/auth/realms/r/.well-known/openid-configuration",
        REACT_APP_OIDC_FM: "false",
    },
    app: {
        REACT_APP_BASE_URL: "https://analytics-be.dev.ohisurvey.com/api",
        REACT_APP_NAVIGATE_PATH: "/text-analytics",
    },
};
const dev = {
    oidc: {
        REACT_APP_OIDC_CLIENT_ID: "f7f81a44-ebb7-4119-b491-ebdfdb849b1f",
        REACT_APP_REDIRECT_BASE_URL: "https://analytics.dev.ohisurvey.com",
        REACT_APP_OIDC_CONFIG_URL: "https://auth.int.mckinsey.id/auth/realms/r/.well-known/openid-configuration",
        REACT_APP_OIDC_FM: "false",
    },
    app: {
        REACT_APP_BASE_URL: "https://analytics-be.dev.ohisurvey.com/api",
        REACT_APP_NAVIGATE_PATH: "/text-analytics",
    },
};
const stg = {
    oidc: {
        REACT_APP_OIDC_CLIENT_ID: "f7f81a44-ebb7-4119-b491-ebdfdb849b1f",
        REACT_APP_REDIRECT_BASE_URL: "https://analytics.stg.ohisurvey.com",
        REACT_APP_OIDC_CONFIG_URL: "https://auth.int.mckinsey.id/auth/realms/r/.well-known/openid-configuration",
        REACT_APP_OIDC_FM: "false",
    },
    app: {
        REACT_APP_BASE_URL: "https://analytics.stg.ohisurvey.com/textanalytics/api",
        REACT_APP_NAVIGATE_PATH: "/text-analytics",
    },
};

const prod = {
    oidc: {
        REACT_APP_OIDC_CLIENT_ID: "436fb4b8-ef73-4101-882b-b941521f4fbb",
        REACT_APP_REDIRECT_BASE_URL: "https://analytics.ohisurvey.com",
        REACT_APP_OIDC_CONFIG_URL: "https://auth.mckinsey.id/auth/realms/r/.well-known/openid-configuration",
        REACT_APP_OIDC_FM: "true",
    },
    app: {
        REACT_APP_BASE_URL: "https://analytics.ohisurvey.com/textanalytics/api",
        REACT_APP_NAVIGATE_PATH: "/text-analytics",
    },
};

const getAppConfig = (env: string): IENV => {
    switch (env) {
        case "prod":
            return prod;
        case "stg":
            return stg;
        case "local":
            return local;
        case "dev":
            return dev;
        default:
            return local;
    }
};
const config = {
    ...getAppConfig(process.env.REACT_APP_STAGE as string),
};
export default config;
