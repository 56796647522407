import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PresetState {
    selectedPresetId: string;
}

const initialState: PresetState = {
    selectedPresetId: "",
};

const presetSlice = createSlice({
    name: "preset",
    initialState,
    reducers: {
        setSelectedPreset(state, action: PayloadAction<string>) {
            state.selectedPresetId = action.payload;
        },
        resetPreset(state) {
            state.selectedPresetId = ""; // Reset to initial state
        },
    },
});

export const { setSelectedPreset, resetPreset } = presetSlice.actions;
export default presetSlice.reducer;
