import React from "react";

import RightArrow from "../../assets/arrow-right.svg";

import styles from "./styles.module.css";

interface WrapperProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
    showButton?: boolean;
    onClick?: () => void;
}

const Wrapper: React.FC<WrapperProps> = ({ children, style, showButton, onClick }) => {
    return (
        <div className={styles.wrapper} style={style}>
            {children}
            {showButton && (
                <div className={styles.buttonContainer}>
                    <div className={styles.button} onClick={onClick}>
                        Explore more
                        <span>
                            <img src={RightArrow} alt="" />{" "}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Wrapper;
