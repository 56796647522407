import React from "react";

import { InfoButton, DisabledSaveButton } from "../../styled/mui-styled";

import styles from "./styles.module.css";

interface InfoBadgeProps {
    preset: {
        icon: string;
        removeIcon: string;
        mainText: string;
        subText: string | React.ReactNode;
        height: string;
        background: string;
    };
    onRemoveClick: () => void;
    showButtons?: boolean;
    showTextField?: boolean;
    textFieldPlaceholder?: string;
    presetName?: string;
    onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSaveApplyClick?: () => void;
    isSaveApply?: boolean;
    isDeleteVisible?: boolean; // New prop to determine if delete button should be shown
    onDeleteClick?: () => void; // New prop for delete action handler
}

const InfoBadge: React.FC<InfoBadgeProps> = ({
    preset,
    showButtons = false,
    showTextField = false,
    textFieldPlaceholder = "",
    presetName,
    onInputChange,
    onRemoveClick,
    onSaveApplyClick,
    isSaveApply = true,
    isDeleteVisible = false, // Default to false
    onDeleteClick,
}) => {
    const { icon, removeIcon, mainText, subText, height, background } = preset;

    return (
        <div className={styles.badge} style={{ height, background }}>
            <div className={styles.align}>
                <div className={styles.infoIcon}>
                    <img src={icon} alt="icon" width={16} height={16} />
                </div>
                <div className={styles.infoText}>{mainText}</div>
                <div className={styles.removeIcon} onClick={onRemoveClick} role="button" tabIndex={0} onKeyDown={(e) => e.key === "Enter" && onRemoveClick()} aria-label="Remove">
                    <img src={removeIcon} alt="Remove Icon" width={19} height={16} />
                </div>
            </div>

            <div className={styles.subText}>{subText}</div>

            {/* Conditionally render the text field if showTextField is true */}
            {showTextField && (
                <div className={styles.textField}>
                    <input id="preset-name" type="text" placeholder={textFieldPlaceholder} value={presetName} onChange={onInputChange} className={styles.textInput} />
                </div>
            )}

            {/* Conditionally render the buttons if showButtons is true */}
            {showButtons && (
                <div className={styles.infoButton}>
                    <InfoButton onClick={onRemoveClick}>CANCEL</InfoButton>
                    {isDeleteVisible ? (
                        <InfoButton onClick={onDeleteClick}>DELETE</InfoButton>
                    ) : presetName && isSaveApply ? (
                        <InfoButton onClick={onSaveApplyClick}>SAVE & APPLY</InfoButton>
                    ) : (
                        <DisabledSaveButton>SAVE & APPLY</DisabledSaveButton>
                    )}
                </div>
            )}
        </div>
    );
};

export default InfoBadge;
