/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

import { QUESTIONS_HEADERS, QUESTIONS_COLUMN_WIDTHS } from "../../constants/constants";
import { QuestionResponse, extendedApiWrapper, QuestionRequest } from "../../services/api-handlers";
import { useAppSelector } from "../../store/hooks";
import LinearProgress from "../progress/linear-progress";
import SentimentTable from "../table/table";

import styles from "./styles.module.css";

const keyExtractor = (header: string) => {
    const map: Record<string, keyof QuestionResponse> = {
        Questions: "question_name",
        "Sentiment Breakdown": "sentiment_breakdown",
        "Response Percentage": "mention_percentage",
    };
    return map[header];
};

const Questions = () => {
    const [data, setData] = useState<QuestionResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    const selectedSurveyId = useAppSelector((state) => state.surveys.selectedSurveyId);
    const filters = useAppSelector((state) => state.selectedFilters.selectedFilters);

    const fetchQuestions = async (appliedFilters: Record<string, string[]> | null = null) => {
        if (selectedSurveyId) {
            setLoading(true);
            try {
                const requestBody: QuestionRequest = {
                    limit: 5,
                    filters: appliedFilters ?? filters,
                };
                const response = await extendedApiWrapper.getAllQuestions(selectedSurveyId, requestBody);
                if (response.data && Array.isArray(response.data)) {
                    setData(response.data);
                } else {
                    setError(true);
                }
            } catch (err) {
                setError(true);
            } finally {
                setLoading(false);
            }
        }
    };
    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            fetchQuestions(filters);
        } else {
            fetchQuestions();
        }
    }, [selectedSurveyId, filters]);

    if (loading) {
        return (
            <div className={styles.loader}>
                <CircularProgress size={50} />
            </div>
        );
    }

    if (error) {
        return <div>Error fetching questions.</div>;
    }
    if (data.length === 0) {
        return <>No data to display</>;
    }

    return (
        <SentimentTable
            headers={QUESTIONS_HEADERS}
            data={data}
            columnWidths={QUESTIONS_COLUMN_WIDTHS}
            keyExtractor={keyExtractor}
            renderCell={(item, columnIndex) => {
                switch (columnIndex) {
                    case 0:
                        return item.question_name;
                    case 1:
                        return <LinearProgress sentimentBreakdown={item.sentiment_breakdown} />;
                    case 2:
                        return `${item.mention_percentage}%`;
                    default:
                        return null;
                }
            }}
        />
    );
};

export default Questions;
