/* eslint-disable @typescript-eslint/no-unused-vars */
import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

import { THEME_HEADERS, THEME_COLUMN_WIDTHS } from "../../constants/constants";
import { ThemeResponse, extendedApiWrapper, QuestionRequest } from "../../services/api-handlers";
import { useAppSelector } from "../../store/hooks";
import LinearProgress from "../progress/linear-progress";
import SentimentTable from "../table/table";

import styles from "./styles.module.css";

export const keyExtractor = (header: string) => {
    const map: Record<string, keyof ThemeResponse> = {
        "Automated Workplace Themes": "topic_name",
        "Sentiment Breakdown": "sentiment_breakdown",
        "Mention Percentage": "mention_percentage",
        "Number of Comments": "comment_count",
    };
    return map[header];
};

const Themes = () => {
    const [data, setData] = useState<ThemeResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    const selectedSurveyId = useAppSelector((state) => state.surveys.selectedSurveyId);
    const filters = useAppSelector((state) => state.selectedFilters.selectedFilters);

    const fetchWorkplaceThemes = async (appliedFilters: Record<string, string[]> | null = null) => {
        if (selectedSurveyId) {
            setLoading(true);
            try {
                const requestBody: QuestionRequest = {
                    limit: 5,
                    filters: appliedFilters ?? filters,
                };
                const response = await extendedApiWrapper.getWorkplaceThemes(selectedSurveyId, requestBody);
                if (response.data && Array.isArray(response.data)) {
                    setData(response.data);
                } else {
                    setError(true);
                }
            } catch (err) {
                setError(true);
            } finally {
                setLoading(false);
            }
        }
    };
    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            fetchWorkplaceThemes(filters);
        } else {
            fetchWorkplaceThemes();
        }
    }, [selectedSurveyId, filters]);

    if (loading) {
        return (
            <div className={styles.loader}>
                <CircularProgress size={50} />
            </div>
        );
    }

    if (error) {
        return <div>Error fetching themes.</div>;
    }
    if (data.length === 0) {
        return <>No data to display</>;
    }
    return (
        <div>
            <SentimentTable
                headers={THEME_HEADERS}
                data={data}
                columnWidths={THEME_COLUMN_WIDTHS}
                keyExtractor={keyExtractor}
                renderCell={(item, columnIndex) => {
                    switch (columnIndex) {
                        case 0:
                            return item.topic_name;
                        case 1:
                            return <LinearProgress sentimentBreakdown={item.sentiment_breakdown} />;
                        case 2:
                            return `${item.mention_percentage}%`;
                        case 3:
                            return item.comment_count;
                        default:
                            return null;
                    }
                }}
            />
        </div>
    );
};
export default Themes;
