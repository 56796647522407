/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { extendedApiWrapper, UserPresetFilters, FilterPresetResponse, FilterPresetRequest } from "../services/api-handlers";

interface Preset {
    id: string;
    name: string;
}

interface UserFilterPresetsState {
    fullPresetData: UserPresetFilters; // Store the entire data fetched from the API
    presets: Preset[]; // Store the transformed data
    loading: boolean;
    error: string | null;
}

// Initial state
const initialState: UserFilterPresetsState = {
    fullPresetData: [],
    presets: [],
    loading: false,
    error: null,
};

// Thunk to fetch user filter presets
export const fetchUserFilterPresets = createAsyncThunk("userFilterPresets/fetchUserFilterPresets", async (surveyId: string) => {
    if (!surveyId) throw new Error("Survey ID is required");
    try {
        const response = await extendedApiWrapper.getUserFilterPreset(surveyId);
        return response.data ?? [];
    } catch (error) {
        throw new Error("Failed to fetch user filter presets");
    }
});

// Thunk to delete a filter preset
export const deleteUserFilterPreset = createAsyncThunk("userFilterPresets/deleteUserFilterPreset", async ({ surveyId, presetId }: { surveyId: string; presetId: string }) => {
    if (!surveyId || !presetId) {
        throw new Error("Params are missing");
    }

    try {
        const response = await extendedApiWrapper.deleteFilterPreset(surveyId, presetId);
        if (!response.data || typeof response.data.success !== "boolean") {
            throw new Error("Unexpected response format");
        }

        return { success: response.data.success, presetId };
    } catch (error) {
        throw new Error("Failed to delete filter preset");
    }
});

// Thunk to save (update) a filter preset
export const saveUserFilterPreset = createAsyncThunk("userFilterPresets/saveUserFilterPreset", async ({ surveyId, preset }: { surveyId: string; preset: FilterPresetRequest }) => {
    if (!surveyId) throw new Error("Survey ID is required");
    try {
        const response = await extendedApiWrapper.updateFilterPreset(surveyId, preset);
        return response.data; // Assuming response.data contains the updated preset
    } catch (error) {
        throw new Error("Failed to save filter preset");
    }
});

// Create the slice
const userFilterPresetsSlice = createSlice({
    name: "userFilterPresets",
    initialState,
    reducers: {
        clearUserFilterPresets: (state) => {
            state.fullPresetData = [];
            state.presets = [];
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetch Presets
            .addCase(fetchUserFilterPresets.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserFilterPresets.fulfilled, (state, action) => {
                state.fullPresetData = action.payload;
                state.presets = action.payload.map((preset: any) => ({
                    id: preset.id,
                    name: preset.name,
                }));
                state.loading = false;
            })
            .addCase(fetchUserFilterPresets.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? "Failed to fetch user filter presets";
            })
            // Delete Preset
            .addCase(deleteUserFilterPreset.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteUserFilterPreset.fulfilled, (state, action) => {
                if (action.payload.success) {
                    // Remove the preset from the state
                    state.fullPresetData = state.fullPresetData.filter((preset) => preset.id !== action.payload.presetId);
                    state.presets = state.presets.filter((preset) => preset.id !== action.payload.presetId);
                }
                state.loading = false;
            })
            .addCase(deleteUserFilterPreset.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? "Failed to delete filter preset";
            })
            // Save Preset
            .addCase(saveUserFilterPreset.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(saveUserFilterPreset.fulfilled, (state, action) => {
                const updatedPreset = action.payload as FilterPresetResponse; // Ensure type is correct

                // Update the full preset data and presets array
                const index = state.fullPresetData.findIndex((preset) => preset.id === updatedPreset.id);
                if (index !== -1) {
                    // Update existing preset
                    state.fullPresetData[index] = updatedPreset;
                    state.presets[index] = { id: updatedPreset.id, name: updatedPreset.name };
                } else {
                    // Handle the case where the preset doesn't exist
                    state.fullPresetData.push(updatedPreset);
                    state.presets.push({ id: updatedPreset.id, name: updatedPreset.name });
                }
                state.loading = false;
            });
    },
});

export const { clearUserFilterPresets } = userFilterPresetsSlice.actions;
export default userFilterPresetsSlice.reducer;
