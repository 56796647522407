import { Box, Paper, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

import * as React from "react";

const AntTabs = styled(Tabs)({
    "& .MuiTabs-indicator": {
        backgroundColor: "#051C2C",
        borderBottom: "3px solid #051C2C",
    },
    marginBottom: "30px",
});

function samePageLinkNavigation(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (event.defaultPrevented || event.button !== 0 || event.metaKey || event.ctrlKey || event.altKey || event.shiftKey) {
        return false;
    }
    return true;
}

interface LinkTabProps {
    label?: string;
    href?: string;
    selected?: boolean;
}

const LinkTab: React.FC<LinkTabProps> = (props: LinkTabProps) => {
    return (
        <Tab
            sx={{
                textTransform: "none",
                marginRight: "8px",
                "&.Mui-selected": {
                    color: "#051C2C",
                    fontWeight: 400,
                    fontSize: "16px",
                    fontFamily: "MckinseySans-Regular",
                },
            }}
            component="a"
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                if (samePageLinkNavigation(event)) {
                    event.preventDefault();
                }
            }}
            aria-current={props.selected && "page"}
            {...props}
        />
    );
};

interface TabContent {
    label: string;
    href: string;
    content: React.ReactNode;
}

interface NavTabsProps {
    tabs: TabContent[];
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export default function NavTabs({ tabs, value, onChange }: NavTabsProps) {
    return (
        <Box sx={{ width: "100%" }}>
            <AntTabs value={value} onChange={onChange} aria-label="nav tabs example" role="navigation">
                {tabs.map((tab, index) => (
                    <LinkTab key={index} label={tab.label} href={tab.href} selected={value === index} />
                ))}
            </AntTabs>
            <Paper elevation={0}>{tabs[value]?.content}</Paper>
        </Box>
    );
}
