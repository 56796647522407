/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CircularProgress } from "@mui/material";

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import ActiveFilter from "../../assets/active_filter.svg";
import FilterIcon from "../../assets/filter.svg";
import { SUMMARISATION_SLIDER_LABELS, PERSISTENT_SENTIMENT_TAGS, TAG_TO_SUMMARY_TYPE, ERROR_MSG, POLL_INTERVAL, MAX_POLL_ATTEMPTS, NO_DATA_MSG } from "../../constants/constants";
import { extendedApiWrapper, QuestionListResponse, SummarizedTextResponse, SummarizedTextRequest } from "../../services/api-handlers";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { showSnackbar } from "../../store/snackbar-slice";
import { mapSliderValueToLength } from "../../utils/utility-function";
import CustomizedDialog from "../dialog/timeout-dialog";
import Filter from "../filter/filter";
import MessageCard from "../message-card/message-card";
import PersistentTags from "../persistent-tags/persistent-tags";
import RangeSlider from "../range-slider/range-slider";
import SelectDropdown from "../select-dropdown/select-dropdown";

import QuestionCard from "./question-card";
import RowRadioButtonsGroup from "./radio-group";

import styles from "./styles.module.css";
import FilterSummaryLoader from "./summary-loader";

const TextSummarisation = () => {
    const [sliderValue, setSliderValue] = useState<number>(0);
    const [activeTags, setActiveTags] = useState<string[]>(["overall_summary"]);
    const [selectedQuestion, setSelectedQuestion] = useState<QuestionListResponse | null>(null);
    const [questions, setQuestions] = useState<QuestionListResponse[]>([]);
    const [selectedFormat, setSelectedFormat] = useState<string>("paragraph");
    const [summarizedText, setSummarizedText] = useState<SummarizedTextResponse | null>(null);
    const [summaryLoading, setSummaryLoading] = useState(false);
    const [summaryError, setSummaryError] = useState(false);
    const [unauthorized, setUnauthorized] = useState(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
    const [filterLoading, setFilterLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const pollingInterval = useRef<NodeJS.Timeout | null>(null);
    const selectedSurveyId = useAppSelector((state) => state.surveys.selectedSurveyId);
    const filters = useAppSelector((state) => state.selectedFilters.selectedFilters);

    const mapActiveTagsToSummaryTypes = (tags: string[]): string[] => {
        if (tags.length === 0) {
            return ["overall_summary"];
        }
        return tags.map((tag) => TAG_TO_SUMMARY_TYPE[tag]);
    };

    useEffect(() => {
        const fetchQuestions = async () => {
            if (!selectedSurveyId) return;
            try {
                const response = await extendedApiWrapper.getQuestionsList(selectedSurveyId);
                if (response.data && Array.isArray(response.data)) {
                    setQuestions(response.data);
                    setSelectedQuestion(response.data[0]);
                } else if (response.status === 401) {
                    setUnauthorized(true);
                }
            } catch (error) {
                console.error("Error fetching questions:", error);
            }
        };

        fetchQuestions();
    }, [selectedSurveyId]);

    const fetchSummarizedText = async (appliedFilters: Record<string, string[]> | null = null) => {
        if (pollingInterval.current) {
            // Clear any ongoing polling before starting a new one
            clearInterval(pollingInterval.current);
            pollingInterval.current = null;
        }
        if (selectedSurveyId && selectedQuestion) {
            const isFilterApplied = !!appliedFilters || Object.keys(filters).length > 0;
            setFilterLoading(isFilterApplied);
            setSummaryLoading(true);
            setSummaryError(false);
            setSummarizedText(null);

            const requestData: SummarizedTextRequest = {
                survey_id: selectedSurveyId,
                question_id: selectedQuestion.id,
                summary_type: mapActiveTagsToSummaryTypes(activeTags),
                content_type: selectedFormat,
                summary_length_type: mapSliderValueToLength(sliderValue),
                filters: appliedFilters ?? filters,
            };

            let response;

            try {
                response = await extendedApiWrapper.getSummarizedText(requestData);

                if (response.status === 202) {
                    let attempts = 0;

                    pollingInterval.current = setInterval(async () => {
                        if (attempts >= MAX_POLL_ATTEMPTS) {
                            clearInterval(pollingInterval.current!);
                            setSummaryLoading(false);
                            setFilterLoading(false);
                            setSummaryError(true);
                            setSummarizedText(null);
                            console.error("Polling timed out");
                            return;
                        }

                        try {
                            response = await extendedApiWrapper.getSummarizedText(requestData);
                            if (response.status === 200 && response.data) {
                                clearInterval(pollingInterval.current!);
                                dispatch(showSnackbar());
                                setSummarizedText(response.data);
                                setFiltersApplied(isFilterApplied);
                                setSummaryLoading(false);
                                setFilterLoading(false);
                            }
                        } catch (error) {
                            clearInterval(pollingInterval.current!);
                            setSummaryError(true);
                            console.error("Error during polling:", error);
                        }
                        attempts += 1;
                    }, POLL_INTERVAL);
                } else if (response.data) {
                    setSummarizedText(response.data);
                    setFiltersApplied(isFilterApplied);
                } else if (response.status === 401) {
                    setUnauthorized(true);
                    setSummaryError(true);
                }
            } catch (error) {
                console.error("Error fetching summarized text:", error);
                setSummaryError(true);
            } finally {
                if (response && response.status !== 202) {
                    setSummaryLoading(false);
                    setFilterLoading(false);
                }
            }
        }
    };

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            fetchSummarizedText(filters);
        } else {
            fetchSummarizedText();
        }
    }, [selectedQuestion, sliderValue, selectedFormat, activeTags, filters]);

    const handleSliderChange = (value: number) => {
        setSliderValue(value);
    };

    const handleTagClick = (tag: string) => {
        const updatedTags = activeTags.includes(tag) ? activeTags.filter((t) => t !== tag) : [...activeTags, tag];

        if (updatedTags.length > 0 && updatedTags.includes("overall_summary")) {
            setActiveTags(updatedTags.filter((tag) => tag !== "overall_summary"));
        } else if (updatedTags.length === 0) {
            setActiveTags(["overall_summary"]);
        } else {
            setActiveTags(updatedTags);
        }
    };

    const handleSelectChange = (values: QuestionListResponse[]) => {
        setSelectedQuestion(values.length > 0 ? values[0] : null);
    };

    const handleFormatChange = (value: string) => {
        setSelectedFormat(value);
    };

    const selectedQuestionIndex = questions.findIndex((q) => q.id === selectedQuestion?.id);
    const handleClose = () => {
        setUnauthorized(false);
        navigate(0);
    };
    const handleFilterOpen = () => {
        setIsOpen(true);
    };
    const handleFilterClose = () => {
        setIsOpen(false);
    };
    const onApplyFilter = (filters: { selected: Record<string, string[]> }) => {
        const appliedFilters = filters.selected;
        fetchSummarizedText(appliedFilters);
        handleFilterClose();
    };
    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.content}>Text Summarisation</div>
                <button className={styles.filter} onClick={handleFilterOpen}>
                    <img src={filtersApplied ? ActiveFilter : FilterIcon} alt="filter" width={16} height={16} />
                    <span>Filters</span>
                </button>
            </div>
            <div className={styles.filterContainer}>
                <div className={styles.col1}>Personalize:</div>
                <div className={styles.col2}>
                    <RowRadioButtonsGroup onChange={handleFormatChange} />
                </div>
                <div className={styles.col3}>
                    <RangeSlider min={0} max={100} defaultValue={0} labels={SUMMARISATION_SLIDER_LABELS} onChange={handleSliderChange} />
                </div>
                <div className={styles.col4}>
                    <PersistentTags tags={PERSISTENT_SENTIMENT_TAGS} activeTags={activeTags} onTagClick={handleTagClick} />
                </div>
            </div>
            <div className={styles.dropdownContainer}>
                <SelectDropdown
                    options={questions}
                    width={"40%"}
                    labelField="name"
                    valueField="id"
                    placeholder="Select question"
                    onChange={handleSelectChange}
                    multi={false}
                    dropdownGap={0}
                    values={selectedQuestion ? [selectedQuestion] : []}
                />
            </div>
            <div className={styles.questionContainer}>
                {filterLoading && <FilterSummaryLoader />}
                {summaryLoading && !filterLoading && (
                    <div className={styles.loader}>
                        <CircularProgress size={50} />
                    </div>
                )}
                {summaryError && <MessageCard title={ERROR_MSG.title} subtitle={ERROR_MSG.subtitle} text={ERROR_MSG.text} />}
                {!filterLoading && !summaryLoading && !summaryError && summarizedText?.data.length === 0 && (
                    <MessageCard title={NO_DATA_MSG.title} subtitle={NO_DATA_MSG.subtitle} text={NO_DATA_MSG.text} />
                )}
                {!filterLoading && !summaryLoading && !summaryError && summarizedText && summarizedText.data.length > 0 && selectedQuestion && (
                    <QuestionCard
                        tag={selectedQuestionIndex + 1}
                        question={selectedQuestion.name}
                        overall_summary={
                            summarizedText?.data[0]?.overall_summary && {
                                text: summarizedText.data[0].overall_summary.text,
                                keywords: summarizedText.data[0].overall_summary.keywords,
                            }
                        }
                        positive_summary={
                            summarizedText?.data[0]?.positive_summary && {
                                text: summarizedText.data[0].positive_summary.text,
                                keywords: summarizedText.data[0].positive_summary.keywords,
                            }
                        }
                        negative_summary={
                            summarizedText?.data[0]?.negative_summary && {
                                text: summarizedText.data[0]?.negative_summary.text,
                                keywords: summarizedText.data[0]?.negative_summary.keywords,
                            }
                        }
                        neutral_summary={
                            summarizedText?.data[0]?.neutral_summary && {
                                text: summarizedText.data[0].neutral_summary.text,
                                keywords: summarizedText.data[0].neutral_summary.keywords,
                            }
                        }
                    />
                )}
            </div>
            <Filter open={isOpen} handleFilterClose={handleFilterClose} onApplyFilter={onApplyFilter} />
            {unauthorized && <CustomizedDialog open={unauthorized} handleClick={handleClose} />}
        </div>
    );
};

export default TextSummarisation;
