import React from "react";

import styles from "./styles.module.css";

interface RangeSliderProps {
    min: number;
    max: number;
    step?: number;
    labels: string[];
    defaultValue: number;
    onChange: (value: number) => void;
}

const RangeSlider: React.FC<RangeSliderProps> = ({ min, max, step = 1, labels, defaultValue, onChange }) => {
    const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(event.target.value);
        onChange(newValue);
    };

    return (
        <div className={styles.sliderContainer}>
            <input type="range" min={min} max={max} step={step} defaultValue={defaultValue} className={styles.slider} onChange={handleSliderChange} />
            <div className={styles.sliderLabels}>
                {labels.map((label) => (
                    <span key={label} style={{ left: `${(labels.indexOf(label) / (labels.length - 1)) * 100}%` }}>
                        {label}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default RangeSlider;
