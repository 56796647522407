import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

interface DialogTitleProps {
    children?: React.ReactNode;
}
type DialogProps = {
    open: boolean;
    handleClick?: () => void;
};
const BootstrapDialogTitle = ({ children }: DialogTitleProps) => {
    return <DialogTitle sx={{ m: 0, p: 2 }}>{children}</DialogTitle>;
};

const CustomizedDialog = ({ open, handleClick }: DialogProps) => {
    return (
        <div>
            <BootstrapDialog aria-labelledby="customized-dialog-title" open={open} sx={{ "& .MuiDialog-paper": { width: "480px" } }}>
                <BootstrapDialogTitle>Session timeout</BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>You have been logged out due to inactivity. Please refresh this page or click continue.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClick}>
                        Continue
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
};
export default CustomizedDialog;
