import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import React, { useEffect } from "react";

import { SentimentResponse } from "../../services/api-handlers";
import { formatNumber } from "../../utils/utility-function";

interface PieChartProps {
    sentimentData: SentimentResponse;
}

const PieChart: React.FC<PieChartProps> = ({ sentimentData }) => {
    const totalComments = sentimentData.total_comments;
    const positiveCount = sentimentData.sentiment_data.find((item) => item.name === "Positive")?.count ?? 0;
    const neutralCount = sentimentData.sentiment_data.find((item) => item.name === "Neutral")?.count ?? 0;
    const negativeCount = sentimentData.sentiment_data.find((item) => item.name === "Negative")?.count ?? 0;
    const formattedTotal = formatNumber(totalComments);
    const options: Highcharts.Options = {
        chart: {
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            type: "pie",
            options3d: {
                enabled: true,
                alpha: 45,
            },
            backgroundColor: "transparent",
        },
        title: {
            text: `<span style="font-size:36px; color:#051C2C; font-family:Bower"><b>${formattedTotal}</b></span><br/><br/><span style="color:#051C2C;font-size:16px; font-weight:300; font-family: MckinseySans-Regular">total comments</span>`,
            verticalAlign: "middle",
        },
        plotOptions: {
            pie: {
                size: "80%",
                innerSize: "85%",
                depth: 45,
                dataLabels: {
                    enabled: false,
                },
                borderWidth: 0,
                borderColor: "transparent",
                borderRadius: "none",
            },
        },
        series: [
            {
                type: "pie",
                data: [
                    { y: positiveCount, color: "#12A195" },
                    { y: neutralCount, color: "#E6E6E6" },
                    { y: negativeCount, color: "#FF7A9F" },
                ],
            },
        ],
        credits: {
            enabled: false,
        },
    };

    useEffect(() => {
        Highcharts.setOptions({
            lang: {
                thousandsSep: ",",
            },
        });
    }, []);

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PieChart;
