import Add from "../assets/add.svg";
import DangerIcon from "../assets/danger.svg";
import RemoveIcon from "../assets/remove.svg";
import WarningIcon from "../assets/warning.svg";

import config from "../config/config";

export const TOOLTIP_TEXT = "Wordcloud is generated from comments based on questions";
export const WORDCLOUD_PLACEHOLDER = {
    theme: "Select workplace theme",
    textSummarisation: "Select question",
};
export const SUMMARISATION_SLIDER_LABELS = ["Short", "Long"];
export const WORDCLOUD_SLIDER_LABELS = ["50", "100", "150", "200"];
export const PERSISTENT_SENTIMENT_TAGS = ["Positive", "Neutral", "Negative"];

export const APPLICATION_TOKEN_ERROR = "Error fetching application token:";
export const SURVEY_LIST_PAGE_LIMIT = 9;
export const ERROR_MSG = {
    title: "Something went wrong.",
    subtitle: "We encountered an error while trying to connect with the server.",
    text: "Please try again in a moment.",
};
export const NO_DATA = {
    title: "No results found.",
    subtitle: "The survey you’re trying to find does not exist in our system.",
    text: "Please try searching for another survey.",
};
export const THEME_HEADERS = ["Automated Workplace Themes", "Sentiment Breakdown", "Mention Percentage", "Number of Comments"];
export const THEME_COLUMN_WIDTHS = ["30%", "40%", "15%", "15%"];
export const QUESTIONS_HEADERS = ["Questions", "Sentiment Breakdown", "Response Percentage"];
export const QUESTIONS_COLUMN_WIDTHS = ["40%", "40%", "20%"];
export const PATH = `${config.app.REACT_APP_NAVIGATE_PATH}/surveys`;
export const FEEDBACK_SUBJECTS = ["Usability", "Features", "Look and feel", "Topic categorisation", "Other"];
export const FEEDBACK_SUBJECT_ERROR = "Please select at least one feedback category.";
export const FEEDBACK_DESCRIPTION_ERROR = "Please enter a feedback description.";
export const COMMENT_DUMMY_TEXT =
    "It was easy to understand the I was able to share my vision towards a common goal with the team It was easy to understand the I was able to share my vision towards a common goal with the team. ";
export const FORMAT_OPTIONS = [
    { value: "paragraph", label: "Paragraph" },
    { value: "bullet_points", label: "Bullet Points" },
    // { value: "numerics", label: "Numerics" },
];

export const TAG_TO_SUMMARY_TYPE: { [key: string]: string } = {
    overall_summary: "overall_summary",
    Positive: "positive_summary",
    Negative: "negative_summary",
    Neutral: "neutral_summary",
};
export const SENTIMENT_COLORS: { [key: string]: string } = {
    Positive: "#12A195",
    Negative: "#FF7A9F",
    Neutral: "#E6E6E6",
};
export const NO_DATA_MSG = {
    title: "No results found.",
    subtitle: "It looks like we don't have any data to display.",
    text: "Please try again later or select a different survey.",
};
export const NO_WORDCLOUD_DATA = "Currently, there isn't enough data to generate a word cloud. Please check back later or explore other questions.";
export const NO_WORDS = {
    title: "No data.",
    subtitle: "No words found for the selected criteria.",
    text: "Please try adjusting your selection or slider.",
};
export const PRESET_EDIT = {
    mainText: "Filter cannot be selected in this preset!",
    subText: "To make changes in this preset, click \u201CEdit\u201D and save changes.",
    icon: WarningIcon,
    removeIcon: RemoveIcon,
    height: "5.25rem",
    background: "#FFF3CE",
};
export const THRESHOLD_ERROR = {
    mainText: "Threshold Not Met!",
    subText: "Insufficient data for analysis. Please adjust your filters.",
    icon: WarningIcon,
    removeIcon: RemoveIcon,
    height: "5.25rem",
    background: "#FFF3CE",
};
export const DELETE_PRESET = {
    mainText: "Delete Preset?",
    subText: (
        <>
            <p>Deleting this preset will result in removing it from the platform.</p>
            <p>Are you sure you want to continue?</p>
        </>
    ),
    icon: DangerIcon,
    removeIcon: RemoveIcon,
    height: "8rem",
    background: "#FDE7E7",
};
export const SAVE_PRESET = {
    mainText: "Save Preset?",
    subText: "Preset name",
    icon: Add,
    removeIcon: RemoveIcon,
    height: "9.5rem",
    background: "#FFFFFF",
};
export const BADGE_INPUT_PLACEHOLDER = "Enter the name of preset to save";

export const RESPONDENTS = "Total respondents:";
export const TOTAL_COMMENTS = "Total comments:";
export const THRESHOLD_REPORTING = "Threshold for reporting:";
export const SUMMARY_READY = {
    title: "Summarisation response is ready!",
    text: (
        <>
            <p>Your customised summary is now ready with the</p>
            <p>selected filters.</p>
        </>
    ),
};
export const POLL_INTERVAL = 30000;
export const MAX_POLL_ATTEMPTS = 10;
