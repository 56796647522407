import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";

import CloudUpload from "../../assets/cloud.svg";

import styles from "./styles.module.css";

interface UploaderProps {
    onImageUpload: (base64Image: string) => void;
}

export function Uploader({ onImageUpload }: UploaderProps) {
    const [images, setImages] = React.useState<ImageListType>([]);

    const onChange = (imageList: ImageListType) => {
        setImages(imageList);
        if (imageList.length > 0 && imageList[0].dataURL) {
            onImageUpload(imageList[0].dataURL);
        }
    };

    return (
        <div className="App">
            <ImageUploading value={images} onChange={onChange}>
                {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                    <div className="upload__image-wrapper">
                        {imageList.length === 0 ? (
                            <button className={styles.imageUploader} style={isDragging ? { color: "red" } : undefined} onClick={onImageUpload} {...dragProps}>
                                <span style={{ marginRight: "5px" }}>
                                    <img src={CloudUpload} alt="upload" />
                                </span>
                                <span style={{ color: "#051C2C" }}>Upload image</span>
                            </button>
                        ) : (
                            imageList.map((image, index) => (
                                <div key={index} className={styles.imagePreviewWrapper}>
                                    <img src={image.dataURL} alt="" width="100" />
                                    <div className={styles.closeButton} onClick={() => onImageRemove(index)}>
                                        <CloseIcon className={styles.closeIcon} />
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                )}
            </ImageUploading>
        </div>
    );
}
