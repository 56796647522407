import { configureStore } from "@reduxjs/toolkit";

import presetReducer from "./preset-slice";
import selectedFilterReducer from "./selected-filter-slice";
import snackbarReducer from "./snackbar-slice";
import surveyDetailsReducer from "./survey-details-slice";
import surveyReducer from "./survey-slice";
import userFilterPresetsReducer from "./user-filter-preset";

export const makeStore = () => {
    return configureStore({
        reducer: {
            surveys: surveyReducer,
            surveyDetails: surveyDetailsReducer,
            userFilterPresets: userFilterPresetsReducer,
            selectedFilters: selectedFilterReducer,
            preset: presetReducer,
            snackbar: snackbarReducer,
        },
    });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
