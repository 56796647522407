/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseIcon from "@mui/icons-material/Close";
import { Box, CircularProgress } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import InfoIcon from "../../assets/info_icon.svg";
import { TOOLTIP_TEXT, WORDCLOUD_SLIDER_LABELS, ERROR_MSG, NO_WORDS } from "../../constants/constants";
import { extendedApiWrapper, QuestionListResponse, AllThemesResponse, WordCount } from "../../services/api-handlers";
import { useAppSelector } from "../../store/hooks";
import { BootstrapTooltip, CustomDialog } from "../../styled/mui-styled";
import { getLastPathSegment, getLabelByPathSegment } from "../../utils/utility-function";

import MessageCard from "../message-card/message-card";
import { WordCloudItem } from "../overview/wordcloud";
import RangeSlider from "../range-slider/range-slider";
import SelectDropdown from "../select-dropdown/select-dropdown";

import WordCloudChart from "./highcharts-word-cloud";
import styles from "./styles.module.css";

interface DialogProps {
    open: boolean;
    handleCloudClose: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const AlertDialogSlide: React.FC<DialogProps> = ({ open, handleCloudClose }) => {
    const [sliderValue, setSliderValue] = useState<number>(50);
    const [selectedData, setSelectedData] = useState<QuestionListResponse | AllThemesResponse | null>(null);
    const [questions, setQuestions] = useState<QuestionListResponse[]>([]);
    const [themes, setThemes] = useState<AllThemesResponse[]>([]);
    const [cloudData, setCloudData] = useState<WordCloudItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
    const selectedSurveyId = useAppSelector((state) => state.surveys.selectedSurveyId);
    const filters = useAppSelector((state) => state.selectedFilters.selectedFilters);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const location = useLocation();
    const lastSegment = getLastPathSegment(location.pathname);

    useEffect(() => {
        if (lastSegment === "text-summarisation" && selectedSurveyId) {
            const fetchQuestions = async () => {
                setLoading(true);
                setError(null);
                try {
                    const response = await extendedApiWrapper.getQuestionsList(selectedSurveyId);
                    if (response.data && Array.isArray(response.data)) {
                        setQuestions(response.data);
                        setSelectedData(response.data[0]);
                    }
                } catch (error) {
                    console.error("Error fetching questions:", error);
                    setError("Failed to fetch questions.");
                } finally {
                    setLoading(false);
                }
            };
            fetchQuestions();
        }
    }, [selectedSurveyId, lastSegment]);

    useEffect(() => {
        if (lastSegment === "workplace-themes" && selectedSurveyId) {
            const fetchThemes = async () => {
                setLoading(true);
                setError(null);
                try {
                    const response = await extendedApiWrapper.getAllThemes(selectedSurveyId);
                    if (response.data) {
                        setThemes(response.data);
                        setSelectedData(response.data[0]);
                    }
                } catch (error) {
                    console.error("Error fetching themes:", error);
                    setError("Failed to fetch themes.");
                } finally {
                    setLoading(false);
                }
            };
            fetchThemes();
        }
    }, [selectedSurveyId, lastSegment]);

    const fetchWordCloud = async (appliedFilters: Record<string, string[]> | null = null) => {
        if (selectedSurveyId && selectedData) {
            setLoading(true);
            setError(null);
            try {
                const requestBody = {
                    limit: sliderValue,
                    filter_by: lastSegment === "text-summarisation" ? `question_id:${selectedData.id}` : `topic_id:${selectedData.id}`,
                    filters: appliedFilters ?? filters,
                };
                const response = await extendedApiWrapper.getWordCloud(selectedSurveyId, requestBody);
                if (response.data) {
                    const mappedData = response.data.map((item: WordCount) => ({
                        name: item.word,
                        weight: item.count,
                    }));
                    setCloudData(mappedData);
                    setFiltersApplied(!!appliedFilters || Object.keys(filters).length > 0);
                }
            } catch (error) {
                console.error("Error fetching word cloud data:", error);
                setError("Failed to fetch word cloud data.");
            } finally {
                setLoading(false);
            }
        }
    };
    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            fetchWordCloud(filters);
        } else {
            fetchWordCloud();
        }
    }, [selectedSurveyId, selectedData, sliderValue, lastSegment, filters]);

    const handleSliderChange = (value: number) => {
        setSliderValue(value);
    };

    const dropdownData = lastSegment === "text-summarisation" ? questions : themes;
    const label = getLabelByPathSegment(lastSegment);
    const handleSelectChange = (values: QuestionListResponse[] | AllThemesResponse[]) => {
        setSelectedData(values.length > 0 ? values[0] : null);
    };

    return (
        <CustomDialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            fullScreen={fullScreen}
            PaperProps={{
                style: {
                    height: "100vh",
                    maxHeight: "100vh",
                    width: fullScreen ? "100%" : "66%",
                    position: "fixed",
                    top: 0,
                    right: 0,
                    margin: 0,
                },
            }}
            onClose={handleCloudClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="customized-dialog-title">WordCloud</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleCloudClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent
                sx={{
                    "&.MuiDialogContent-root": {
                        padding: "20px",
                    },
                }}
            >
                {filtersApplied && <Box className={styles.filterText}>Note: Filters from the {lastSegment} page are applied to this word cloud for consistency.</Box>}
                <Box className={styles.container}>
                    <Box className={styles.customText}>Personalize:</Box>
                    <Box className={styles.questionContainer}>
                        <SelectDropdown
                            options={dropdownData}
                            width={"90%"}
                            labelField="name"
                            valueField="id"
                            placeholder={label}
                            onChange={handleSelectChange}
                            multi={false}
                            dropdownGap={0}
                            values={selectedData ? [selectedData] : []}
                        />
                        <BootstrapTooltip title={TOOLTIP_TEXT}>
                            <span>
                                <img src={InfoIcon} alt="" />
                            </span>
                        </BootstrapTooltip>
                    </Box>
                    <Box className={styles.sliderContainer}>
                        <Box className={styles.customWordText}>Total words: {sliderValue}</Box>
                        <Box style={{ paddingLeft: "10px", marginTop: "10px" }}>
                            <RangeSlider min={50} max={200} defaultValue={50} labels={WORDCLOUD_SLIDER_LABELS} step={50} onChange={handleSliderChange} />
                        </Box>
                    </Box>
                </Box>
                <Box style={{ marginTop: "30px" }}>
                    {loading ? (
                        <div className={styles.loader}>
                            <CircularProgress size={50} />
                        </div>
                    ) : error ? (
                        <MessageCard title={ERROR_MSG.title} subtitle={ERROR_MSG.subtitle} text={ERROR_MSG.text} />
                    ) : cloudData.length === 0 ? (
                        <MessageCard title={NO_WORDS.title} subtitle={NO_WORDS.subtitle} text={NO_WORDS.text} />
                    ) : (
                        <WordCloudChart data={cloudData} wordSpaces={2} />
                    )}
                </Box>
            </DialogContent>
        </CustomDialog>
    );
};

export default AlertDialogSlide;
