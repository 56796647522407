/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { CircularProgress } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";

import FilterSummaryLoader from "../../components/text-summarisation/summary-loader";
import { MAX_POLL_ATTEMPTS, POLL_INTERVAL } from "../../constants/constants";
import { extendedApiWrapper, SummarizedTextRequest, SummarizedTextResponse } from "../../services/api-handlers";
import { useAppSelector } from "../../store/hooks";
import QuestionCard from "../text-summarisation/question-card";

import styles from "./styles.module.css";

const TextSummary = () => {
    const [summarizedText, setSummarizedText] = useState<SummarizedTextResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [filterLoading, setFilterLoading] = useState(false);

    const pollingInterval = useRef<NodeJS.Timeout | null>(null);
    const selectedSurveyId = useAppSelector((state) => state.surveys.selectedSurveyId);
    const filters = useAppSelector((state) => state.selectedFilters.selectedFilters);

    const fetchQuestionsAndSummarize = async (appliedFilters: Record<string, string[]> | null = null) => {
        if (pollingInterval.current) {
            clearInterval(pollingInterval.current);
            pollingInterval.current = null;
        }
        if (!selectedSurveyId) return;
        setLoading(true);
        setError(null);
        let summaryResponse;
        try {
            const questionResponse = await extendedApiWrapper.getQuestionsList(selectedSurveyId);
            const questions = questionResponse.data;

            if (questions) {
                const isFilterApplied = !!appliedFilters || Object.keys(filters).length > 0;
                setFilterLoading(isFilterApplied);
                const questionIds = questions.slice(0, 3).map((question) => question.id);
                const summarizedTextRequest: SummarizedTextRequest = {
                    survey_id: selectedSurveyId,
                    question_id: questionIds,
                    summary_type: "overall_summary",
                    content_type: "paragraph",
                    summary_length_type: "short",
                    filters: appliedFilters ?? filters,
                };
                summaryResponse = await extendedApiWrapper.getSummarizedText(summarizedTextRequest);
                if (summaryResponse.status === 202) {
                    let attempts = 0;

                    pollingInterval.current = setInterval(async () => {
                        if (attempts >= MAX_POLL_ATTEMPTS) {
                            if (pollingInterval.current) {
                                clearInterval(pollingInterval.current);
                                pollingInterval.current = null;
                            }
                            setLoading(false);
                            setFilterLoading(false);
                            setError("Error fetching summarized text");
                            setSummarizedText(null);
                            console.error("Polling timed out");
                            return;
                        }

                        try {
                            summaryResponse = await extendedApiWrapper.getSummarizedText(summarizedTextRequest);
                            if (summaryResponse.status === 200 && summaryResponse.data) {
                                if (pollingInterval.current) {
                                    clearInterval(pollingInterval.current);
                                    pollingInterval.current = null;
                                }
                                setSummarizedText(summaryResponse.data);

                                setLoading(false);
                                setFilterLoading(false);
                            }
                        } catch (error) {
                            if (pollingInterval.current) {
                                clearInterval(pollingInterval.current);
                                pollingInterval.current = null;
                            }
                            setError("Error fetching summarized text");
                            console.error("Error during polling:", error);
                        }
                        attempts += 1;
                    }, POLL_INTERVAL);
                } else if (summaryResponse.data) {
                    setSummarizedText(summaryResponse.data);
                }
            }
        } catch (error) {
            console.error("Error fetching summarized text:", error);
            setError("Error fetching summarized text");
        } finally {
            if (summaryResponse && summaryResponse.status !== 202) {
                setLoading(false);
                setFilterLoading(false);
            }
        }
    };

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            fetchQuestionsAndSummarize(filters);
        } else {
            fetchQuestionsAndSummarize();
        }
    }, [selectedSurveyId, filters]);

    if (filterLoading) {
        return (
            <div>
                <FilterSummaryLoader />
            </div>
        );
    }
    if (loading && !filterLoading) {
        return (
            <div className={styles.loader}>
                <CircularProgress size={50} />
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            {summarizedText?.data?.length ? (
                <div className={styles.gridContainer}>
                    {summarizedText.data.map((summary, index) => (
                        <QuestionCard
                            key={summary.question_id}
                            tag={index + 1}
                            question={summary.question}
                            overall_summary={{
                                text: summary.overall_summary.text,
                                keywords: summary.overall_summary.keywords,
                            }}
                            showCopyIcon={false}
                        />
                    ))}
                </div>
            ) : (
                <div>No summaries available for the selected survey.</div>
            )}
        </div>
    );
};

export default TextSummary;
