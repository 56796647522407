import React, { useState } from "react";

import styles from "./styles.module.css";

interface TableProps<T> {
    headers: string[];
    data: T[];
    renderCell: (item: T, columnIndex: number) => React.ReactNode;
    renderHeader?: (header: string, index: number) => React.ReactNode;
    styles?: Record<string, string>;
    columnWidths?: (string | undefined)[];
    sortIcon?: string;
    keyExtractor: (header: string) => keyof T;
    sortableColumns?: number[];
}

const Table = <T,>({ headers, data, renderCell, styles: customStyles, columnWidths, sortIcon, keyExtractor, sortableColumns = [] }: TableProps<T>) => {
    const [sortColumn, setSortColumn] = useState<number | null>(null);
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
    const [sortedData, setSortedData] = useState<T[]>(data);

    const parsePercentage = (value: string) => {
        const percentage = parseFloat(value.replace("%", "").trim());
        return isNaN(percentage) ? 0 : percentage;
    };

    const handleSort = (columnIndex: number) => {
        if (!sortableColumns.includes(columnIndex)) return;
        const isAsc = sortColumn === columnIndex && sortDirection === "asc";
        const direction = isAsc ? "desc" : "asc";
        const key = keyExtractor(headers[columnIndex]);

        setSortColumn(columnIndex);
        setSortDirection(direction);

        const sorted = [...data].sort((a, b) => {
            const aValue = a[key];
            const bValue = b[key];

            if (typeof aValue === "string" && aValue.includes("%")) {
                const aPercentage = parsePercentage(aValue);
                const bPercentage = parsePercentage(bValue as string);
                return direction === "asc" ? aPercentage - bPercentage : bPercentage - aPercentage;
            }

            if (typeof aValue === "string" && typeof bValue === "string") {
                return direction === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }

            if (typeof aValue === "number" && typeof bValue === "number") {
                return direction === "asc" ? aValue - bValue : bValue - aValue;
            }

            return 0;
        });

        setSortedData(sorted);
    };

    return (
        <div className={styles.container}>
            <table className={customStyles?.table ?? styles.table}>
                <colgroup>
                    {headers.map((_, index) => (
                        <col key={index} style={{ width: columnWidths?.[index] }} />
                    ))}
                </colgroup>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index} className={customStyles?.tableHeaderRow ?? styles.tableHeaderRow} onClick={() => handleSort(index)}>
                                <div className={styles.headerContent}>
                                    {header}
                                    {sortableColumns.includes(index) && sortIcon && (
                                        <span className={styles.sortIcon}>
                                            <img src={sortIcon} alt="" />
                                        </span>
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {headers.map((_, colIndex) => (
                                <td key={colIndex} className={customStyles?.tableCell ?? styles.tableCell}>
                                    {renderCell(row, colIndex)}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
